<template v-if="$store.state.user">
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height primary" dark outlined @click.prevent="$store.state.isClient ? DIALOG_SHOW() : $store.dispatch('TOAST', { show: true, message: 'Customer access only!' })">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Add Order
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-3">
        <div class="pl-4 pl-sm-8 pr-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class=""
            placeholder="Nama, email, telepon ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-3 d-flex align-center justify-center">
        <v-menu
          ref="menu"
          v-model="menu"
          :return-value.sync="dates"
          left
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Date Ranges"
              append-icon="mdi-calendar-outline"
              readonly
              outlined
              dense
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
              @click:clear="dates = [], ORDER_GET(tableSearch)"
            />
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            light
            class="force-light-font"
            range
          >
            <!-- <v-spacer />
            <v-btn
              text
              class="text-capitalize"
              small
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="text-capitalize"
              small
              @click="$refs.menu.save(dates), (dates.length === 2 ? ORDER_GET() : '')"
            >
              Ok
            </v-btn> -->
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="px-4 pt-4 pb-4">
          <v-select
            v-model.number="tableSearchStatus"
            :items="StatusOrder"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
            label="Order Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              <div class="d-flex align-center text-uppercase">
                <v-icon class="mr-1">
                  mdi-barcode
                </v-icon>
                <span class="font-weight-bold caption">
                  XTI{{ ('000000' + item.id).slice(-6) }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.shipping_name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex fill-width align-center justify-space-between text-capitalize">
                <span class="font-weight-bold">
                  {{ item.shipping_name }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.shipping_phone`]="{ item }">
            <div class="py-2">
              {{ item.shipping_phone }}
            </div>
          </template>
          <template v-slot:[`item.payment_subtotal`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_subtotal).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.payment_shipping`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_shipping).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.payment_total`]="{ item }">
            <div class="py-2">
              Rp{{ parseInt(item.payment_total).toLocaleString() }}
            </div>
          </template>
          <template #[`item.status`]="{ item }">
            <div class="py-2">
              {{ StatusOrderName(item.status) }}
              <span v-if="item.shipping_ttk" class="ml-1">
                ({{ item.shipping_ttk }})
              </span>
              <div v-if="item.commerce_customer_password">
                Passcode: {{ item.commerce_customer_password }}
              </div>
              <div v-if="item.payment_detail">
                <div v-if="$StrToJson(item.payment_detail).receipt">
                  Payment Receipt :
                  <div>
                    <div class="text-uppercase">
                      {{ $StrToJson(item.payment_detail).name }}, {{ $StrToJson(item.payment_detail).account }} a/n {{ $StrToJson(item.payment_detail).account_number }}
                    </div>
                    <a :href="$StrToJson(item.payment_detail).receipt" target="_blank" class="d-flex align-center">
                      <v-icon small class="mr-1">
                        mdi-receipt
                      </v-icon>
                      <span class="caption">
                        Payment Receipt
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="!parseInt(item.shipping_ttk_id)" depressed small dark color="indigo" class="text-capitalize ma-1" @click.prevent="SET_COMMERCE_TTK(item)">
                <v-icon small class="mr-1">
                  mdi-truck-fast
                </v-icon>
                Set Ready To Ship
              </v-btn>
              <v-btn depressed small dark color="orange" class="text-capitalize ma-1" @click.prevent="DIALOG_SHOW(item)">
                <v-icon small class="mr-1">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
              <v-btn :href="`https://wa.me/62${item.shipping_phone}?text=Passcode: ${item.commerce_customer_password}, Invoice: ${'https://xti.co.id/' + item.invoice_hash}`" target="_blank" depressed small color="green" class="white--text text-capitalize  ma-1">
                <v-icon small class="mr-1">
                  mdi-whatsapp
                </v-icon>
                Share
              </v-btn>
              <v-btn :href="'https://xti.co.id/' + item.invoice_hash" target="_blank" depressed small color="green" class="white--text text-capitalize  ma-1">
                <v-icon small class="mr-1">
                  mdi-invoice-text
                </v-icon>
                Invoice
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <div class="pt-2 pb-2 px-4 px-md-8 white fill-width">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-end flex-column flex-sm-row">
                <div class="d-flex align-center font-weight-bold">
                  Total : {{ tableTotal }}
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialog.data">
        <v-card-title class="body-1 justify-center">
          - {{ dialog.data.id ? (dialog.edit ? 'Update Order' : 'Detail Order') : 'Tambah Order Baru' }} -
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: calc(100vh - 200px)">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center pb-2 justify-space-between font-weight-bold">
                # Buyer
                <v-btn :disabled="!dialog.edit" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="CUSTOMER_SHOW()">
                  <v-icon class="mr-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                    mdi-plus
                  </v-icon>
                  Buyer
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-autocomplete
                    v-model="dialog.data.commerce_customer"
                    :disabled="!dialog.edit"
                    :search-input.sync="customerQuery"
                    :name="Math.random()"
                    :items="Customer.data.map(r => { return { id: r.id, name: r.name, phone: r.phone, email: r.email, status: r.status } })"
                    item-text="name"
                    return-object
                    outlined
                    dense
                    hide-details
                    label="Select Buyer"
                  />
                  <v-btn v-if="dialog.data.commerce_customer" :disabled="!dialog.edit" class="rounded ml-2" depressed @click.prevent="CUSTOMER_SHOW(dialog.data.commerce_customer)">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="">
                  <v-textarea
                    v-model="dialog.data.shipping_address"
                    :disabled="!dialog.edit"
                    outlined
                    rows="2"
                    dense
                    hide-details
                    label="Detail Address"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center justify-space-between pb-2">
                <strong>
                  # Product/Items
                </strong>
                <div v-if="productToggle" class="d-flex align-center">
                  <v-autocomplete
                    v-model="productSelected"
                    :disabled="!dialog.edit"
                    :items="Product.data"
                    item-text="name"
                    return-object
                    :name="Math.random()"
                    :search-input.sync="productQuery"
                    label="Search product ..."
                    outlined
                    autofocus
                    append-icon="mdi-magnify"
                    dense
                    clearable
                    class="rounded-pill"
                    hide-details
                  />
                  <v-btn icon class="ml-2 font-weight-bold text-caption" color="red" @click.prevent="productQuery = '', productToggle = !productToggle">
                    <v-icon class="d-flex align-center" style="font-size:18px;line-height:140%;">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <v-btn v-else :disabled="!dialog.edit" text class="ml-2 font-weight-bold text-caption" color="blue" @click.prevent="productToggle = !productToggle">
                  <v-icon class="mr-1 d-flex align-center" style="font-size:18px;line-height:140%;">
                    mdi-magnify
                  </v-icon>
                  Product
                </v-btn>
              </div>
              <div class="pa-4 rounded grey lighten-4">
                <div v-if="!dialog.data.items.length" class="text-center pa-4">
                  No Product
                </div>
                <div v-for="(i, iI) in dialog.data.items" :key="'product-item-' + iI" class="d-flex align-center justify-space-between text-body-2 my-2">
                  <div>
                    <div class="font-weight-bold text-body-1">
                      {{ iI + 1 }}. {{ i.commerce_product_name }}
                    </div>
                    <div>
                      <span :class="[(parseInt(i.commerce_product_price_discount_temp) ? 'text-decoration-line-through red--text text-caption' : 'font-weight-bold')]">
                        Rp{{ parseInt(i.commerce_product_price_temp || 0).toLocaleString() }}
                      </span>
                      <span v-if="parseInt(i.commerce_product_price_discount_temp)" class="ml-1 font-weight-bold">
                        Rp{{ parseInt(i.commerce_product_price_discount_temp || 0).toLocaleString() }}
                      </span>
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="d-flex align-center justify-end">
                      <v-icon :disabled="!dialog.edit" small @click.prevent="parseInt(dialog.data.items[iI].commerce_product_quantity) > 1 ? dialog.data.items[iI].commerce_product_quantity-- : 1">
                        mdi-minus
                      </v-icon>
                      <input v-model.number="dialog.data.items[iI].commerce_product_quantity" :disabled="!dialog.edit" type="text" style="width:40px;" class="text-center px-1 py-1 mx-2 rounded grey lighten-2">
                      <v-icon :disabled="!dialog.edit" small @click.prevent="dialog.data.items[iI].commerce_product_quantity++">
                        mdi-plus
                      </v-icon>
                    </div>
                    <div class="pt-2 font-weight-bold">
                      Rp{{ ((parseInt(i.commerce_product_price_discount_temp || 0) || parseInt(i.commerce_product_price_temp || 0)) * parseInt(i.commerce_product_quantity || 0)).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right pr-4 pt-4 font-weight-bold text-body-1">
                Subtotal : Rp{{ orderSubtotal.toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" class="">
              <div class="d-flex align-center pb-2 justify-space-between font-weight-bold">
                # Shipping
              </div>
              <v-card outlined class="grey lighten-4 pa-4">
                <v-row>
                  <v-col cols="12" class="col-sm-6">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping_from"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :search-input.sync="areaqueryFrom"
                      :items="areasFrom"
                      item-value="city_id"
                      item-text="area_name"
                      outlined
                      dense
                      hide-details
                      label="Ship From (city name)"
                      autocomplete="disabled"
                      @change="dialog.data.shipping = 0, PRICING_GET"
                    />
                  </v-col>
                  <v-col cols="12" class="col-sm-6">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping_to"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :search-input.sync="areaqueryTo"
                      :items="areasTo"
                      item-value="city_id"
                      item-text="area_name"
                      outlined
                      dense
                      hide-details
                      label="Ship To (city name)"
                      autocomplete="disabled"
                      @change="dialog.data.shipping = 0, PRICING_GET"
                    />
                  </v-col>
                  <v-col cols="12" class="">
                    <v-autocomplete
                      v-model.number="dialog.data.shipping"
                      :disabled="!dialog.edit"
                      :name="Math.random()"
                      :items="shippingPricingData"
                      item-value="id"
                      item-text="price_detail"
                      outlined
                      clearable
                      dense
                      hide-details
                      label="Select Shipping"
                      autocomplete="disabled"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <div class="text-right pr-4 pt-4 font-weight-bold text-body-1">
                Shipping Cost : +Rp{{ orderShippingCost.toLocaleString() }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!dialog.edit"
            color="green darken-1"
            depressed
            dark
            class="text-capitalize px-4 font-weight-bold"
            @click="ORDER_PROCESS()"
          >
            {{ dialog.data.id ? (dialog.edit ? 'Update Order' : 'Detail Order') : 'Place Order' }}
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-model="dialogCustomer.show"
          persistent
          scrollable
          max-width="450"
        >
          <v-card v-if="dialogCustomer.data" class="px-4">
            <v-card-title class="body-1 justify-center text-uppercase font-weight-bold">
              {{ parseInt(dialogCustomer.data.id) ? 'UPDATE' : 'TAMBAH' }} CUSTOMER
            </v-card-title>
            <v-divider />
            <v-card-text class="py-4 px-0" style="max-height: 90vh;overflow:hidden;">
              <v-text-field
                v-model="dialogCustomer.data.name"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Customer Name"
              />
              <v-text-field
                v-model="dialogCustomer.data.email"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Email"
              />
              <v-text-field
                v-model.number="dialogCustomer.data.phone"
                outlined
                dense
                hide-details
                prefix="+62"
                class="mb-4"
                label="Phone Number"
              />
              <v-select
                v-model.number="dialogCustomer.data.status"
                :items="[{ id: -1, name: '- Select Customer -' }, { id: 1, name: 'Active' }, { id: 0, name: 'Not Active' }]"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                dense
                class="d-block mb-4"
                label="Customer Status"
              />
              <v-text-field
                v-model="dialogCustomer.data.password"
                type="text"
                outlined
                dense
                hide-details
                class="mb-4"
                :label="dialogCustomer.data.password ? 'Change Passcode' : 'Update Passcode'"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-0">
              <v-btn
                color="red darken-1"
                text
                @click="dialogCustomer.show = false"
              >
                Close
              </v-btn>
              <v-spacer />
              <v-btn
                :disabled="!dialog.edit"
                color="primary"
                dark
                depressed
                @click="CUSTOMER_PROCESS()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
let searchAreaFromTimeout = null
let searchAreaToTimeout = null
let searchProductTimeout = null
let searchCustomerTimeout = null

export default {
  name: 'Customer',
  data: () => ({
    areaqueryFrom: '',
    areasFrom: [],
    areaqueryTo: '',
    areasTo: [],
    menu: false,
    dates: [],
    headers: [
      {
        text: 'ORDER ID',
        value: 'id'
      },
      { text: 'Name', value: 'shipping_name' },
      { text: 'No Telepon', value: 'shipping_phone' },
      { text: 'Amount Subtotal', value: 'payment_subtotal' },
      { text: 'Amount Shipping', value: 'payment_shipping' },
      { text: 'Amount Total', value: 'payment_total' },
      { text: 'Status', value: 'status' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: -1,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialog: {
      show: false,
      data: null
    },
    loading: false,
    Product: {
      data: [],
      total: 0
    },
    productToggle: false,
    productQuery: '',
    productSelected: null,
    Customer: {
      data: [],
      total: 0
    },
    customerQuery: '',
    customerSelected: null,
    dialogCustomer: {
      show: false,
      edit: false,
      data: null
    },
    shippingPricingData: [],
    StatusOrder: [
      { name: 'Tampilkan Semua', value: -1 },
      { name: 'New Order', value: 0 },
      { name: 'New Order (Paid)', value: 1000 },
      { name: 'Ready To Ship', value: 1 },
      { name: 'Shipping', value: 2 },
      { name: 'Completed', value: 3 },
      { name: 'Canceled', value: 99 }
    ]
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    orderSubtotal () {
      if (this.dialog.data && this.dialog.data.items && this.dialog.data.items.length) {
        const items = this.dialog.data.items || []
        return items.reduce(function (a, b) {
          return a + ((parseInt(b.commerce_product_price_discount_temp) || parseInt(b.commerce_product_price_temp)) * parseInt(b.commerce_product_quantity))
        }, 0)
      }
      return 0
    },
    orderShippingCost () {
      if (this.dialog.data && parseInt(this.dialog.data.shipping) && this.shippingPricingData.length) {
        const costSelected = this.shippingPricingData.find(r => parseInt(r.id) === parseInt(this.dialog.data.shipping))
        if (costSelected) {
          return parseInt(costSelected.price) || 0
        }
      }
      return 0
    }
  },
  watch: {
    dates: function () {
      if (!this.dates.length || this.dates.length === 2) {
        this.$refs.menu.save(this.dates)
        this.ORDER_GET(this.tableSearch)
        this.menu = false
      }
    },
    productSelected (v) {
      if (v) {
        const isExist = this.dialog.data.items.find(r => r.commerce_product === parseInt(v.id))
        if (isExist) {
          this.$store.dispatch('TOAST', { show: true, message: 'Product already selected!' })
          return
        }
        const item = {
          commerce_client: 0,
          commerce_customer: 0,
          commerce_order: parseInt(this.dialog.data.id) || 0,
          commerce_product: parseInt(v.id),
          commerce_product_quantity: 1,
          commerce_product_capture: null,
          commerce_product_name: v.name,
          commerce_product_price: 0,
          commerce_product_price_discount: 0,
          commerce_product_price_temp: parseInt(v.price) || 0,
          commerce_product_price_discount_temp: parseInt(v.price_discount) || 0,
          status: 1
        }
        this.dialog.data.items.push(item)
      }
    },
    productQuery (v) {
      if (searchProductTimeout) {
        clearTimeout(searchProductTimeout)
      }
      searchProductTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    customerQuery (v) {
      if (searchCustomerTimeout) {
        clearTimeout(searchCustomerTimeout)
      }
      searchCustomerTimeout = setTimeout(() => {
        this.CUSTOMER_GET()
      }, 300)
    },
    areaqueryFrom (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areasFrom.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaFromTimeout) {
        clearTimeout(searchAreaFromTimeout)
      }
      searchAreaFromTimeout = setTimeout(() => {
        this.areaGet('from', '?q=' + v)
        clearTimeout(searchAreaFromTimeout)
      }, 300)
    },
    areaqueryTo (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areasTo.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaToTimeout) {
        clearTimeout(searchAreaToTimeout)
      }
      searchAreaToTimeout = setTimeout(() => {
        this.areaGet('to', '?q=' + v)
        clearTimeout(searchAreaToTimeout)
      }, 300)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ORDER_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ORDER_GET(this.tableSearch)
    },
    tableSearchCoordinator () {
      this.ORDER_GET(this.tableSearch)
    },
    options () {
      this.ORDER_GET(this.tableSearch)
    }
  },
  created () {
    // if (this.$store.state.user) {
    //   if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
    //     this.$router.replace('/')
    //   }
    // } else {
    //   this.$router.replace('/')
    // }
  },
  methods: {
    SET_COMMERCE_TTK (payload) {
      if (!confirm('Set as Ready To Ship? auto generate ttk number!')) {
        return false
      }
      this.$store.dispatch('commerce/SET_COMMERCE_TTK', payload)
        .then((res) => {
          if (res.status) {
            this.ORDER_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil digenerate' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    StatusOrderName (st) {
      return ((parseInt(st) >= 0 && parseInt(st) <= 99)
        ? ((this.StatusOrder.find(r => parseInt(r.value) === parseInt(st)) || {}).name || '-')
        : '-')
    },
    async PRICING_GET () {
      const params = {
        vendor: 0,
        q: '?q='
      }
      if (this.dialog.show && this.dialog.data) {
        const dataOrigin = parseInt(this.dialog.data.shipping_from)
        const dataDestination = parseInt(this.dialog.data.shipping_to)
        params.q += `&city_origin=${(dataOrigin || 0)}`
        params.q += `&city_destination=${(dataDestination || 0)}`
        if (!dataOrigin || !dataDestination) {
          return false
        }
        await this.$store.dispatch('logistic/SHIPPING_PRICING_GET', params)
          .then((res) => {
            this.shippingPricingData = res.data.data || []
          })
      }
    },
    CUSTOMER_SHOW (d) {
      if (d) {
        d.password = null
        this.dialogCustomer.data = Object.assign({}, d)
      } else {
        this.dialogCustomer.data = {
          commerce_client: 0,
          name: null,
          email: null,
          phone: null,
          password: null,
          payment_list: null,
          status: 1
        }
      }
      this.dialogCustomer.show = true
    },
    CUSTOMER_GET () {
      this.$store.dispatch('commerce/CUSTOMER_SEARCH', `?q=${(this.customerQuery || '')}&status=1`)
        .then((res) => {
          if (res.status) {
            this.Customer.data = res.data.data.data
            this.Customer.total = parseInt(res.data.data.total) || 0
          } else {
            this.Customer.data = []
            this.Customer.total = 0
          }
        })
    },
    CUSTOMER_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialogCustomer.data)
      if (!processedData.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama customer masih kosong!' })
        return false
      }

      if (!processedData.email) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email masih kosong!' })
        return false
      } else if (!this.$validateEmail(processedData.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }

      if (!parseInt(processedData.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No telepon masih kosong!' })
        return false
      } else if (!this.$validatePhone(processedData.phone + '')) {
        this.$store.dispatch('TOAST', { show: true, message: 'No telepon tidak valid!' })
        return false
      }

      if (!processedData.password) {
        delete processedData.password
      }

      this.loading = true
      this.$store.dispatch('commerce/CUSTOMER_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.status) {
              this.customerQuery = res.data.data.name
              this.dialog.data.commerce_customer = { id: res.data.data.id, name: res.data.data.name, phone: res.data.data.phone, email: res.data.data.email, status: res.data.data.status }
              this.dialogCustomer.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil ditambah' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    PRODUCT_GET () {
      this.$store.dispatch('commerce/PRODUCT_SEARCH', `?q=${(this.productQuery || '')}&status=1`)
        .then((res) => {
          if (res.status) {
            this.Product.data = res.data.data.data
            this.Product.total = parseInt(res.data.data.total) || 0
          } else {
            this.Product.data = []
            this.Product.total = 0
          }
        })
    },
    async DIALOG_SHOW (data) {
      this.productQuery = ''
      this.productSelected = null
      this.dialog.edit = this.$store.state.isClient
      if (data) {
        const d = Object.assign({}, data)
        if (d.shipping_name) {
          this.customerQuery = d.shipping_name
          d.commerce_customer = {
            id: d.commerce_customer,
            name: d.commerce_customer_name,
            phone: d.commerce_customer_phone,
            email: d.commerce_customer_email,
            status: d.commerce_customer_status
          }
        }
        if (d.shipping_from_name && d.shipping_to_name) {
          this.areaqueryFrom = d.shipping_from_name || ''
          this.areaqueryTo = d.shipping_to_name || ''
        }
        if (!d.items) {
          d.items = []
        }
        const items = await this.$store.dispatch('commerce/ORDER_ITEM_SEARCH', d.id)
        if (items && items.status) {
          d.items = items.data.data.items || []
        }
        this.dialog.data = d
      } else {
        this.dialog.data = {
          commerce_client: 0,
          commerce_customer: null,
          commerce_logistic: 0,
          payment_subtotal: 0,
          payment_shipping: 0,
          payment_total: 0,
          payment_timestamp: null,
          payment_detail: null,
          shipping: 0,
          shipping_from: 0,
          shipping_to: 0,
          shipping_name: null,
          shipping_phone: null,
          shipping_address: null,
          status: 0,
          items: []
        }
      }
      this.dialog.show = true
      this.PRICING_GET()
    },
    areaGet (act, q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '') + '&nonKecamatan=1').then((res) => {
        if (res.status) {
          if (act === 'from') {
            this.areasFrom = res.data.data || []
          } else {
            this.areasTo = res.data.data || []
          }
        }
      })
    },
    ORDER_GET (q) {
      let status = this.tableSearchStatus > -1 ? this.tableSearchStatus : ''
      let statusPaid = ''
      this.tableLoading = true
      if (status === 1000) {
        status = 0
        statusPaid = '&statuspaid=1'
      }
      const fromTo = this.GetDates()
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('commerce/ORDER_SEARCH', query + (q ? ('&q=' + q) : '') + fromTo + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + statusPaid)
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
    },
    ORDER_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please wait!' })
        return false
      }
      const processedData = Object.assign({}, this.dialog.data)
      if (!processedData.commerce_customer) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select buyer!' })
        return false
      } else if (!parseInt(processedData.commerce_customer.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid buyer!' })
        return false
      }
      processedData.shipping_name = processedData.commerce_customer.name
      processedData.shipping_phone = processedData.commerce_customer.phone
      processedData.commerce_customer = parseInt(processedData.commerce_customer.id)
      processedData.payment_subtotal = this.orderSubtotal
      processedData.payment_shipping = this.orderShippingCost
      processedData.payment_total = this.orderSubtotal + this.orderShippingCost

      this.loading = true
      this.$store.dispatch('commerce/ORDER_CREATE', processedData)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (!res.data.data.message || res.data.data.message === 'success') {
              this.ORDER_GET(this.tableSearch)
              this.dialog.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedData.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
